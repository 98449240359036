import React from 'react'
import PropTypes from 'prop-types'
import { WelcomeSection, QuoteSection, EpisodesSection } from '../components'
import 'normalize.css/normalize.css'
import '../styles/common.sass'
import SEO from '../components/SEO'

export default function Home() {
  return (
    <>
      <SEO />
      <WelcomeSection />
      <QuoteSection />
      <EpisodesSection episodes={[]} links={''} />
    </>
  )
}
Home.propTypes = {
  data: PropTypes.object
}
